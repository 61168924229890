@use 'sass:color';
@use 'tools' as *;

$hex-green: #8DBB51;
$glow-green: #AFD87E;

.hero-body {
  position: relative;

  * {
    animation-play-state: inherit;
  }

  button {
    position: absolute;
    top: rem(260);
    right: 1rem;
    width: rem(36);
    height: rem(36);
    background-color: $hex-green;
    border: 0;
    border-radius: 50%;
    box-shadow: 0 rem(3) rem(5) rgba($black, 0.25);
    z-index: 10;
    transform: translateX(min(0px, calc(-50vw + rem(800))));
    cursor: pointer;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #224459;
      font-size: 1.5rem;
    }

    &:hover {
      background-color: color.adjust($hex-green, $lightness: -5%);
    }

    &.i-play::before {
      left: calc(50% + 0.125rem);
    }

    @include mobile {
      display: none;
    }
  }

  .hex_animated {
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  [class^=hexagons_outer_] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: .75;

    [class^=hexagons_container_] {
      position: absolute;
      width: rem(640);

      [class^=hexagon_] {
        position: absolute;
        width: 100%;
      }
    }
  }

  .hexagons_outer_M {
    display: none;
  }

  .hexagons_outer_L {
    img {
      transform: scaleX(-1) rotate(30deg);
    }

    .hexagons_container_L {
      top: rem(-40);
      right: 60%;

      .hexagon_L_1 {
        animation-name: hex_L_1;
        animation-duration: 37.3s;
      }

      .hexagon_L_2 {
        filter: opacity(0.4);
        top: rem(-55);
        left: rem(30);
        scale: 0.95;
        animation-name: hex_L_2;
        animation-duration: 19.1s;
      }

      .hexagon_L_3 {
        top: rem(-52);
        left: rem(25);
        scale: 0.80;
        animation-name: hex_L_3;
        animation-duration: 23.5s;
      }
    }
  }

  .hexagons_outer_R {
    img {
      transform: scaleY(-1) rotate(30deg);
    }

    .hexagons_container_R {
      top: rem(-80);
      left: 62%;

      .hexagon_R_1 {
        top: rem(40);
        left: rem(-80);
        animation-name: hex_R_1;
        animation-duration: 39.7s;
      }

      .hexagon_R_2 {
        filter: opacity(0.4);
        top: rem(-20);
        left: rem(-30);
        scale: 0.95;
        animation-name: hex_R_2;
        animation-duration: 27.9s;
      }

      .hexagon_R_3 {
        top: rem(-25);
        left: rem(-25);
        scale: 0.80;
        animation-name: hex_R_3;
        animation-duration: 31.5s;
      }

      @include mobile-tablet {
        left: 65%;
      }
    }
  }

  @include mobile {
    .hexagons_outer_M {
      display: block;

      img {
        transform: scaleY(-1) rotate(-60deg);
      }

      .hexagons_container_M {
        top: rem(-10);
        right: rem(-100);
        width: rem(420);

        .hexagon_M_1 {
          animation-name: hex_M_1;
          animation-duration: 39.7s;
        }

        .hexagon_M_2 {
          filter: opacity(0.4);
          top: rem(-45);
          left: rem(-20);
          scale: 0.95;
          animation-name: hex_M_2;
          animation-duration: 37.5s;
        }

        .hexagon_M_3 {
          top: rem(-65);
          left: rem(-10);
          scale: 0.85;
          animation-name: hex_M_3;
          animation-duration: 35.3s;
        }
      }
    }

    .hexagons_outer_L,
    .hexagons_outer_R {
      display: none;
    }
  }

  .logo_75_outer {
    position: absolute;
    top: rem(-10);
    left: 0;
    width: 100%;
    height: rem(320);
    z-index: 5;

    .logo_75_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1rem;
      position: absolute;
      top: calc(50% - rem(4));
      left: 50%;
      transform: translate(-50%, -50%);

      svg {
        width: rem(150);
        margin: 0 auto;
        transform: translateX(rem(8));
        overflow: visible;

        &.logo-medium {
          width: rem(120);
        }

        &.logo-small {
          width: rem(100);
        }
      }

      .headline_75 {
        width: rem(210);
        color: $white;
        text-align: center;
        font-family: $open-sans;
        font-size: 1.5rem;
        font-weight: $semibold;
      }

      .button-ghost {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        padding: 0.67rem 3.5rem;
        border-color: $glow-green;
        text-align: center;

        &::before {
          content: '';
          position: absolute;
          inset: 0;
          box-shadow: 0 0 #{rem(10)} $glow-green;
          opacity: 0;
          animation: 5s ease-in-out infinite button_glow;
          animation-play-state: inherit;
        }

        &::after {
          content: normal;
        }
      }
    }

    @include mobile {
      width: calc(100vw - rem(160));

      .logo_75_container {
        left: 50%;
        margin-left: 1rem;
        transform: translate(-50%, -50%);

        .button-ghost {
          padding-left: 2rem;
          padding-right: 2rem;
        }
      }
    }
  }

  [class^=hex_imgs_] {
    position: absolute;
    top: rem(-10);
    height: rem(320);
    z-index: 1;

    @include mobile {
      &:not(.hex_imgs_M) {
        display: none;
      }
    }

    .hex_container_imgs {
      position: relative;
      max-width: rem(800);
      height: 100%;
    }

    &.hex_imgs_L,
    &.hex_imgs_R {
      width: 50%;
    }

    &.hex_imgs_L {
      right: 50%;

      .hex_container_imgs {
        margin-left: auto;
      }
    }

    &.hex_imgs_R {
      left: 50%;
    }

    [class^=hex_img_] {
      position: absolute;
      width: calc(rem(302) * (50vw / rem(800)));
      height: rem(262);
      min-width: rem(226.5);
      min-height: rem(196.5);
      max-width: rem(302);
      max-height: rem(262);
      transform-origin: center;
      filter: url(#round_75) drop-shadow(rem(12) rem(8) rem(1) rgba($black, 0.25));

      img {
        clip-path: polygon(50% -50%, 100% 50%, 50% 150%, 0 50%);
      }
    }

    .hex_img_L_1 {
      top: 10%;
      right: 55%;
      z-index: 5;
      scale: 0.56;
      animation-name: hex_img_L_1;
      animation-duration: 17.1s;
    }

    .hex_img_L_2 {
      top: 43%;
      right: 37%;
      scale: 0.8;
      animation-name: hex_img_L_2;
      animation-duration: 29.3s;
    }

    .hex_img_L_3 {
      top: -15%;
      right: 8%;
      z-index: 4;
      scale: 0.56;
      animation-name: hex_img_L_3;
      animation-duration: 25.7s;
    }

    .hex_img_R_1 {
      top: 30%;
      left: 20%;
      scale: 0.61;
      z-index: 5;
      animation-name: hex_img_R_1;
      animation-duration: 26.9s;
    }

    .hex_img_R_2 {
      top: -10%;
      left: 38%;
      scale: 0.85;
      animation-name: hex_img_R_2;
      animation-duration: 22.5s;
    }

    .hex_img_R_3 {
      top: 20%;
      left: 60%;
      scale: 0.6;
      animation-name: hex_img_R_3;
      animation-duration: 28.7s;
    }

    &.hex_imgs_M {
      position: absolute;
      right: rem(-140);


      @include desktop(true) {
        display: none;
      }

      .hex_M_bg,
      .hex_M_img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

      .hex_M_bg {
        width: rem(300);
        height: rem(260);
      }

      .hex_M_img {
        position: absolute;
        left: rem(28);
        width: rem(245);
        height: rem(212);

        img {
          position: absolute;
          clip-path: polygon(50% -50%, 100% 50%, 50% 150%, 0 50%);
          opacity: 0;
          transition: opacity 2s;

          &.active_m_img {
            opacity: 1;
          }
        }

        &:not(:has(.active_m_img)) img:first-child {
          opacity: 1;
        }
      }

      .hex_mask_M {
        position: absolute;
        top: 50%;
        transform: translate(rem(1), -50%) scale(.835);
      }
    }
  }
}

@keyframes button_glow {
  25% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0;
  }
}

@keyframes hex_L_1 {
  to {
    transform: translate(rem(10), rem(20));
  }
}

@keyframes hex_L_2 {
  to {
    transform: translate(rem(20), rem(-35));
  }
}

@keyframes hex_L_3 {
  to {
    transform: translate(rem(-40), rem(25));
  }
}

@keyframes hex_R_1 {
  to {
    transform: translate(rem(80), rem(-40));
  }
}

@keyframes hex_R_2 {
  to {
    transform: translate(rem(22), rem(20));
  }
}

@keyframes hex_R_3 {
  to {
    transform: translate(rem(-75), rem(-40));
  }
}

@keyframes hex_M_1 {
  to {
    transform: translate(rem(40), rem(60));
  }
}

@keyframes hex_M_2 {
  to {
    transform: translate(rem(-20), rem(-50));
  }
}

@keyframes hex_M_3 {
  to {
    transform: translate(rem(60), rem(-60));
  }
}

@keyframes hex_img_L_1 {
  to {
    transform: translate(-30%, -50%);
  }
}

@keyframes hex_img_L_2 {
  to {
    transform: translate(25%, -82%);
  }
}

@keyframes hex_img_L_3 {
  to {
    transform: translate(-50%, 120%);
  }
}

@keyframes hex_img_R_1 {
  to {
    transform: translate(rem(-90), rem(-175));
  }
}

@keyframes hex_img_R_2 {
  to {
    transform: translate(rem(60), rem(180));
  }
}

@keyframes hex_img_R_3 {
  to {
    transform: translate(rem(80), rem(-180));
  }
}
