.hero-body {
  position: relative;
}
.hero-body * {
  animation-play-state: inherit;
}
.hero-body button {
  position: absolute;
  top: 18.5714285714rem;
  right: 1rem;
  width: 2.5714285714rem;
  height: 2.5714285714rem;
  background-color: #8DBB51;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0.2142857143rem 0.3571428571rem rgba(0, 0, 0, 0.25);
  z-index: 10;
  transform: translateX(min(0px, -50vw + 57.1428571429rem));
  cursor: pointer;
}
.hero-body button::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #224459;
  font-size: 1.5rem;
}
.hero-body button:hover {
  background-color: rgb(128.2644628099, 174.3595041322, 68.1404958678);
}
.hero-body button.i-play::before {
  left: calc(50% + 0.125rem);
}
@media screen and (max-width: 649px) {
  .hero-body button {
    display: none;
  }
}
.hero-body .hex_animated {
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.hero-body [class^=hexagons_outer_] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.75;
}
.hero-body [class^=hexagons_outer_] [class^=hexagons_container_] {
  position: absolute;
  width: 45.7142857143rem;
}
.hero-body [class^=hexagons_outer_] [class^=hexagons_container_] [class^=hexagon_] {
  position: absolute;
  width: 100%;
}
.hero-body .hexagons_outer_M {
  display: none;
}
.hero-body .hexagons_outer_L img {
  transform: scaleX(-1) rotate(30deg);
}
.hero-body .hexagons_outer_L .hexagons_container_L {
  top: -2.8571428571rem;
  right: 60%;
}
.hero-body .hexagons_outer_L .hexagons_container_L .hexagon_L_1 {
  animation-name: hex_L_1;
  animation-duration: 37.3s;
}
.hero-body .hexagons_outer_L .hexagons_container_L .hexagon_L_2 {
  filter: opacity(0.4);
  top: -3.9285714286rem;
  left: 2.1428571429rem;
  scale: 0.95;
  animation-name: hex_L_2;
  animation-duration: 19.1s;
}
.hero-body .hexagons_outer_L .hexagons_container_L .hexagon_L_3 {
  top: -3.7142857143rem;
  left: 1.7857142857rem;
  scale: 0.8;
  animation-name: hex_L_3;
  animation-duration: 23.5s;
}
.hero-body .hexagons_outer_R img {
  transform: scaleY(-1) rotate(30deg);
}
.hero-body .hexagons_outer_R .hexagons_container_R {
  top: -5.7142857143rem;
  left: 62%;
}
.hero-body .hexagons_outer_R .hexagons_container_R .hexagon_R_1 {
  top: 2.8571428571rem;
  left: -5.7142857143rem;
  animation-name: hex_R_1;
  animation-duration: 39.7s;
}
.hero-body .hexagons_outer_R .hexagons_container_R .hexagon_R_2 {
  filter: opacity(0.4);
  top: -1.4285714286rem;
  left: -2.1428571429rem;
  scale: 0.95;
  animation-name: hex_R_2;
  animation-duration: 27.9s;
}
.hero-body .hexagons_outer_R .hexagons_container_R .hexagon_R_3 {
  top: -1.7857142857rem;
  left: -1.7857142857rem;
  scale: 0.8;
  animation-name: hex_R_3;
  animation-duration: 31.5s;
}
@media screen and (max-width: 1024px) {
  .hero-body .hexagons_outer_R .hexagons_container_R {
    left: 65%;
  }
}
@media screen and (max-width: 649px) {
  .hero-body .hexagons_outer_M {
    display: block;
  }
  .hero-body .hexagons_outer_M img {
    transform: scaleY(-1) rotate(-60deg);
  }
  .hero-body .hexagons_outer_M .hexagons_container_M {
    top: -0.7142857143rem;
    right: -7.1428571429rem;
    width: 30rem;
  }
  .hero-body .hexagons_outer_M .hexagons_container_M .hexagon_M_1 {
    animation-name: hex_M_1;
    animation-duration: 39.7s;
  }
  .hero-body .hexagons_outer_M .hexagons_container_M .hexagon_M_2 {
    filter: opacity(0.4);
    top: -3.2142857143rem;
    left: -1.4285714286rem;
    scale: 0.95;
    animation-name: hex_M_2;
    animation-duration: 37.5s;
  }
  .hero-body .hexagons_outer_M .hexagons_container_M .hexagon_M_3 {
    top: -4.6428571429rem;
    left: -0.7142857143rem;
    scale: 0.85;
    animation-name: hex_M_3;
    animation-duration: 35.3s;
  }
  .hero-body .hexagons_outer_L,
  .hero-body .hexagons_outer_R {
    display: none;
  }
}
.hero-body .logo_75_outer {
  position: absolute;
  top: -0.7142857143rem;
  left: 0;
  width: 100%;
  height: 22.8571428571rem;
  z-index: 5;
}
.hero-body .logo_75_outer .logo_75_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  position: absolute;
  top: calc(50% - 0.2857142857rem);
  left: 50%;
  transform: translate(-50%, -50%);
}
.hero-body .logo_75_outer .logo_75_container svg {
  width: 10.7142857143rem;
  margin: 0 auto;
  transform: translateX(0.5714285714rem);
  overflow: visible;
}
.hero-body .logo_75_outer .logo_75_container svg.logo-medium {
  width: 8.5714285714rem;
}
.hero-body .logo_75_outer .logo_75_container svg.logo-small {
  width: 7.1428571429rem;
}
.hero-body .logo_75_outer .logo_75_container .headline_75 {
  width: 15rem;
  color: #fff;
  text-align: center;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}
.hero-body .logo_75_outer .logo_75_container .button-ghost {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 0.67rem 3.5rem;
  border-color: #AFD87E;
  text-align: center;
}
.hero-body .logo_75_outer .logo_75_container .button-ghost::before {
  content: "";
  position: absolute;
  inset: 0;
  box-shadow: 0 0 0.7142857143rem #AFD87E;
  opacity: 0;
  animation: 5s ease-in-out infinite button_glow;
  animation-play-state: inherit;
}
.hero-body .logo_75_outer .logo_75_container .button-ghost::after {
  content: normal;
}
@media screen and (max-width: 649px) {
  .hero-body .logo_75_outer {
    width: calc(100vw - 11.4285714286rem);
  }
  .hero-body .logo_75_outer .logo_75_container {
    left: 50%;
    margin-left: 1rem;
    transform: translate(-50%, -50%);
  }
  .hero-body .logo_75_outer .logo_75_container .button-ghost {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.hero-body [class^=hex_imgs_] {
  position: absolute;
  top: -0.7142857143rem;
  height: 22.8571428571rem;
  z-index: 1;
}
@media screen and (max-width: 649px) {
  .hero-body [class^=hex_imgs_]:not(.hex_imgs_M) {
    display: none;
  }
}
.hero-body [class^=hex_imgs_] .hex_container_imgs {
  position: relative;
  max-width: 57.1428571429rem;
  height: 100%;
}
.hero-body [class^=hex_imgs_].hex_imgs_L, .hero-body [class^=hex_imgs_].hex_imgs_R {
  width: 50%;
}
.hero-body [class^=hex_imgs_].hex_imgs_L {
  right: 50%;
}
.hero-body [class^=hex_imgs_].hex_imgs_L .hex_container_imgs {
  margin-left: auto;
}
.hero-body [class^=hex_imgs_].hex_imgs_R {
  left: 50%;
}
.hero-body [class^=hex_imgs_] [class^=hex_img_] {
  position: absolute;
  width: 18.875vw;
  height: 18.7142857143rem;
  min-width: 16.1785714286rem;
  min-height: 14.0357142857rem;
  max-width: 21.5714285714rem;
  max-height: 18.7142857143rem;
  transform-origin: center;
  filter: url(#round_75) drop-shadow(0.8571428571rem 0.5714285714rem 0.0714285714rem rgba(0, 0, 0, 0.25));
}
.hero-body [class^=hex_imgs_] [class^=hex_img_] img {
  clip-path: polygon(50% -50%, 100% 50%, 50% 150%, 0 50%);
}
.hero-body [class^=hex_imgs_] .hex_img_L_1 {
  top: 10%;
  right: 55%;
  z-index: 5;
  scale: 0.56;
  animation-name: hex_img_L_1;
  animation-duration: 17.1s;
}
.hero-body [class^=hex_imgs_] .hex_img_L_2 {
  top: 43%;
  right: 37%;
  scale: 0.8;
  animation-name: hex_img_L_2;
  animation-duration: 29.3s;
}
.hero-body [class^=hex_imgs_] .hex_img_L_3 {
  top: -15%;
  right: 8%;
  z-index: 4;
  scale: 0.56;
  animation-name: hex_img_L_3;
  animation-duration: 25.7s;
}
.hero-body [class^=hex_imgs_] .hex_img_R_1 {
  top: 30%;
  left: 20%;
  scale: 0.61;
  z-index: 5;
  animation-name: hex_img_R_1;
  animation-duration: 26.9s;
}
.hero-body [class^=hex_imgs_] .hex_img_R_2 {
  top: -10%;
  left: 38%;
  scale: 0.85;
  animation-name: hex_img_R_2;
  animation-duration: 22.5s;
}
.hero-body [class^=hex_imgs_] .hex_img_R_3 {
  top: 20%;
  left: 60%;
  scale: 0.6;
  animation-name: hex_img_R_3;
  animation-duration: 28.7s;
}
.hero-body [class^=hex_imgs_].hex_imgs_M {
  position: absolute;
  right: -10rem;
}
@media print, screen and (min-width: 650px) {
  .hero-body [class^=hex_imgs_].hex_imgs_M {
    display: none;
  }
}
.hero-body [class^=hex_imgs_].hex_imgs_M .hex_M_bg,
.hero-body [class^=hex_imgs_].hex_imgs_M .hex_M_img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.hero-body [class^=hex_imgs_].hex_imgs_M .hex_M_bg {
  width: 21.4285714286rem;
  height: 18.5714285714rem;
}
.hero-body [class^=hex_imgs_].hex_imgs_M .hex_M_img {
  position: absolute;
  left: 2rem;
  width: 17.5rem;
  height: 15.1428571429rem;
}
.hero-body [class^=hex_imgs_].hex_imgs_M .hex_M_img img {
  position: absolute;
  clip-path: polygon(50% -50%, 100% 50%, 50% 150%, 0 50%);
  opacity: 0;
  transition: opacity 2s;
}
.hero-body [class^=hex_imgs_].hex_imgs_M .hex_M_img img.active_m_img {
  opacity: 1;
}
.hero-body [class^=hex_imgs_].hex_imgs_M .hex_M_img:not(:has(.active_m_img)) img:first-child {
  opacity: 1;
}
.hero-body [class^=hex_imgs_].hex_imgs_M .hex_mask_M {
  position: absolute;
  top: 50%;
  transform: translate(0.0714285714rem, -50%) scale(0.835);
}

@keyframes button_glow {
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
}
@keyframes hex_L_1 {
  to {
    transform: translate(0.7142857143rem, 1.4285714286rem);
  }
}
@keyframes hex_L_2 {
  to {
    transform: translate(1.4285714286rem, -2.5rem);
  }
}
@keyframes hex_L_3 {
  to {
    transform: translate(-2.8571428571rem, 1.7857142857rem);
  }
}
@keyframes hex_R_1 {
  to {
    transform: translate(5.7142857143rem, -2.8571428571rem);
  }
}
@keyframes hex_R_2 {
  to {
    transform: translate(1.5714285714rem, 1.4285714286rem);
  }
}
@keyframes hex_R_3 {
  to {
    transform: translate(-5.3571428571rem, -2.8571428571rem);
  }
}
@keyframes hex_M_1 {
  to {
    transform: translate(2.8571428571rem, 4.2857142857rem);
  }
}
@keyframes hex_M_2 {
  to {
    transform: translate(-1.4285714286rem, -3.5714285714rem);
  }
}
@keyframes hex_M_3 {
  to {
    transform: translate(4.2857142857rem, -4.2857142857rem);
  }
}
@keyframes hex_img_L_1 {
  to {
    transform: translate(-30%, -50%);
  }
}
@keyframes hex_img_L_2 {
  to {
    transform: translate(25%, -82%);
  }
}
@keyframes hex_img_L_3 {
  to {
    transform: translate(-50%, 120%);
  }
}
@keyframes hex_img_R_1 {
  to {
    transform: translate(-6.4285714286rem, -12.5rem);
  }
}
@keyframes hex_img_R_2 {
  to {
    transform: translate(4.2857142857rem, 12.8571428571rem);
  }
}
@keyframes hex_img_R_3 {
  to {
    transform: translate(5.7142857143rem, -12.8571428571rem);
  }
}